import { template as template_abd904e88aef46fe8c7562406210e033 } from "@ember/template-compiler";
const FKLabel = template_abd904e88aef46fe8c7562406210e033(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
