import { template as template_0cc16e73342f439f93f5be85163942f3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0cc16e73342f439f93f5be85163942f3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
